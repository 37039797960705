import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes, BrowserRouter } from 'react-router-dom'
import './scss/style.scss'
import 'react-datetime-picker/dist/DateTimePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'react-clock/dist/Clock.css'
import { Helmet } from 'react-helmet'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const LoginSso = React.lazy(() => import('./views/pages/login/LoginSso'))
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

//RUM
const rum_environment = process.env.REACT_APP_RUM_ENVIRONMENT
const rum_service = process.env.REACT_APP_RUM_SERVICE_NAME
const rum_server = process.env.REACT_APP_RUM_SERVER_URL
const script_code = `elasticApm.init({serviceName: '${rum_service}',serverUrl: '${rum_server}',environment: '${rum_environment}'})`

class App extends Component {
  render() {
    return (
      <>
        {rum_environment ? (
          <Helmet>
            <script
              src="https://unpkg.com/@elastic/apm-rum/dist/bundles/elastic-apm-rum.umd.min.js"
              crossOrigin
            ></script>
            <script type="text/javascript" dangerouslySetInnerHTML={{ __html: script_code }} />
          </Helmet>
        ) : (
          ''
        )}
        <HashRouter>
          <Suspense fallback={loading}>
            <Routes>
              <Route exact path="/loginsso" name="Login Page" element={<LoginSso />} />
              <Route exact path="/login" name="Login Page" element={<Login />} />
              <Route exact path="/register" name="Register Page" element={<Register />} />
              <Route exact path="/404" name="Page 404" element={<Page404 />} />
              <Route exact path="/500" name="Page 500" element={<Page500 />} />
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </HashRouter>
      </>
    )
  }
}

export default App
